* {
    padding: 0;
    margin: 0;
}

#root, .container {
    text-align: center;
}


canvas {
    background: #eee;
    display: block;
    margin: 0 auto;
}

